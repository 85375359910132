/* styles.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header {
  background-color: #f0f2f5;
  padding: 20px 10%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

nav li {
  margin-right: 10px;
  background-color: #fff;
  border-radius: 5px;
  width: 120px;
  height: 50px; /* 调整导航项的高度 */
  transition: background-color 0.3s;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

nav li a {
  color: #1890ff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center; /* 垂直居中 */
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: Arial, sans-serif; /* 设置字体 */
  font-size: 16px; /* 设置字体大小 */
  font-weight: bold; /* 设置字体加粗 */
}

nav li:hover {
  background-color: #f0f2f5;
}

main {
  margin: 0px auto;
  /*padding: 0 200px; !* 添加内边距 *!*/
  max-width: 800px;
  min-width: 500px;
}

/*@media (max-width: 768px) { !* 小屏幕设备 *!*/
/*  main {*/
/*    padding: 0 0px; !* 较小的内边距 *!*/
/*  }*/
/*}*/



footer {
  background-color: #001529;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

/* Logo 样式 */
.logo {
  font-size: 24px;
  font-weight: bold;
  color: #1890ff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  display: inline-block;
  line-height: 50px;
  text-align: center;
  width: 150px;
}
